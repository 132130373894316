// stores/currentUserStore.js
import { defineStore } from 'pinia';
import axios from 'axios';

export const useCurrentUserStore = defineStore('currentUser', {
  state: () => ({
    user: {},
  }),
  actions: {
    async fetchCurrentUser() {
      try {
        this.user.token = document.getElementById("vue-app").dataset.userToken;
        this.user.firstName = document.getElementById("vue-app").dataset.userFirstName;
        this.user.lastName = document.getElementById("vue-app").dataset.userLastName;
        this.user.id = parseInt(document.getElementById("vue-app").dataset.userId);
        this.user.accountId = parseInt(document.getElementById("vue-app").dataset.accountId);
        this.setAxiosAuthToken();
      } catch (error) {
        console.error('Failed to fetch current user:', error);
      }
    },
    setAxiosAuthToken() {
      if (this.user.token) {
        axios.defaults.headers.common['Authorization'] = `token ${this.user.token}`;
      }
    },
    clearUser() {
      this.user = {};
      delete axios.defaults.headers.common['Authorization'];
    }
  },
});
